import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';
import { TeacherPageControllerService } from 'src/app/features/teacher-book/services/teacher-page-controller.service';
import bookConfig from 'src/app/shared/constants/book-config';

import {
  GetProfessorContentAction,
  GetSummaryAction,
  SetBookIdAction
} from './book.actions';
import {
  BookColorsModel,
  BookMascotModel,
  BookProfessorModel,
  BookSerieModel
} from './book.model';
import { PageModel } from '../../models/book.model';
import { ConfigService } from '../../services/config.service';
import { BookService } from '../../services/requests/book.service';

export interface BookStateModel {
  portfolioId: number | null;
  bookId: number | null;
  colors: BookColorsModel | null;
  mascot: BookMascotModel | null;
  serie: BookSerieModel | null;
  school: string | null;
  professor: BookProfessorModel | null;
}

const BOOK_STATE_TOKEN = new StateToken<BookStateModel>('book');

@State<BookStateModel>({
  name: BOOK_STATE_TOKEN,
  defaults: {
    portfolioId: null,
    bookId: null,
    colors: null,
    mascot: null,
    serie: null,
    school: null,
    professor: null
  }
})
@Injectable()
export class BookState {
  constructor(
    private bookService: BookService,
    private teacherPageController: TeacherPageControllerService,
    private configService: ConfigService
  ) {}

  get conectaBookIds() {
    return this.configService.config?.conectaBookIds || [];
  }

  @Selector() static portfolioId({
    portfolioId
  }: BookStateModel): number | null {
    return portfolioId;
  }

  @Selector() static bookId({ bookId }: BookStateModel): number | null {
    return bookId;
  }

  @Selector() static externalStrapiId({
    serie
  }: BookStateModel): number | null {
    return serie?.externalStrapiId ?? null;
  }

  @Selector() static school({ school }: BookStateModel): string | null {
    return school;
  }

  @Selector() static serie({ serie }: BookStateModel): BookSerieModel | null {
    return serie;
  }

  @Selector() static colors({
    colors
  }: BookStateModel): BookColorsModel | null {
    return colors;
  }

  @Selector() static mascot({
    mascot
  }: BookStateModel): BookMascotModel | null {
    return mascot;
  }

  @Selector() static professor({
    professor
  }: BookStateModel): BookProfessorModel | null {
    return professor;
  }

  @Action(SetBookIdAction) setBookId(
    { patchState }: StateContext<BookStateModel>,
    { payload }: SetBookIdAction
  ): void {
    patchState({
      bookId: payload
    });
  }

  @Action(GetSummaryAction) getSummary(
    { getState }: StateContext<BookStateModel>,
    { payload }: GetSummaryAction
  ) {
    const { bookId } = payload;

    return this.bookService
      .getSummary({
        bookId: String(bookId)
      })
      .pipe(
        tap(({ pages }) => this._handleRenderPages(pages, getState(), bookId))
      );
  }

  @Action(GetProfessorContentAction) getProfessorContent(
    { patchState }: StateContext<BookStateModel>,
    { payload }: GetProfessorContentAction
  ) {
    return this.bookService.getProfessorContent(String(payload.bookId)).pipe(
      tap(({ attributes }) => {
        const { colors, mascot } =
          bookConfig[attributes?.serie.id_externo_strapi] || {};

        patchState({
          portfolioId: attributes?.portfolio?.data.id,
          colors,
          mascot,
          school: attributes?.escola,
          serie: {
            color: attributes?.serie.cor,
            externalSegmentId: attributes?.serie.id_externo_segmento,
            externalStrapiId: attributes?.serie.id_externo_strapi,
            id: attributes?.serie.id,
            name: attributes?.serie.nome,
            className: attributes?.turma,
            classDescription: attributes?.pagina_turma?.descricao,
            classPhoto:
              attributes?.pagina_turma?.midia?.data?.attributes?.formats?.small
                ?.url
          },
          professor: {
            name: attributes?.professor?.data?.attributes?.apelido,
            description: attributes?.professor?.data?.attributes?.descricao,
            photo:
              attributes?.professor?.data?.attributes?.foto?.data?.attributes
                ?.url
          }
        });
      })
    );
  }

  private _handleRenderPages(
    pages: PageModel,
    state: BookStateModel,
    bookId: number
  ) {
    const { serie, professor } = state;
    const { classPhoto, classDescription } = serie || {};
    const { photo: professorPhoto, description: professorBio } =
      professor || {};
    const isConectaBook = this.conectaBookIds.includes(bookId);

    const shouldRenderAuthorsPage = !!(
      classPhoto ||
      classDescription ||
      professorPhoto ||
      professorBio
    );

    this.teacherPageController.buildPagesArray(
      pages,
      isConectaBook,
      shouldRenderAuthorsPage
    );
  }
}
